<template>
    <!-- 예약팝업 -->
    <div v-show="pp">
        <div class="basic_pp bottom_up" :class="{'off':off}">
            <div class="dimm bg-body-i bg-opacity-50" @click="pp_off()"></div>
            <div class="content bg-body overflow-hidden px-0">
                <div class="header position-sticky flex-between-center pb-3 text-truncate px-3">
                    <h5>태그(개발참고용)</h5>
                    <i class="p-2 fal fa-times" @click="pp_off()"></i>
                </div>
                <div class="body overflow-auto pt-1" ref="basic_pp_body">
                    <ul class="d-flex justify-content-center flex-wrap">

                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #6ab2a4; color: ${$autoTxtColor('#6ab2a4')}`" @click="$copy('#6ab2a4')">#6ab2a4</li>

                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #2eb180; color: ${$autoTxtColor('#2eb180')}`" @click="$copy('#2eb180')">#2eb180</li>
                        
                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #3f9185; color: ${$autoTxtColor('#3f9185')}`" @click="$copy('#3f9185')">#3f9185</li>



                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #6c7ca9; color: ${$autoTxtColor('#6c7ca9')}`" @click="$copy('#6c7ca9')">#6c7ca9</li>

                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #436b8e; color: ${$autoTxtColor('#436b8e')}`" @click="$copy('#436b8e')">#436b8e</li>
                        
                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #434a80; color: ${$autoTxtColor('#434a80')}`" @click="$copy('#434a80')">#434a80</li>
                        
                        
                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #7fb7dc; color: ${$autoTxtColor('#7fb7dc')}`" @click="$copy('#7fb7dc')">#7fb7dc</li>

                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #3f91b7; color: ${$autoTxtColor('#3f91b7')}`" @click="$copy('#3f91b7')">#3f91b7</li>

                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #03789b; color: ${$autoTxtColor('#03789b')}`" @click="$copy('#03789b')">#03789b</li>


                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #a0a0a0; color: ${$autoTxtColor('#a0a0a0')}`" @click="$copy('#a0a0a0')">#a0a0a0</li>

                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #666868; color: ${$autoTxtColor('#666868')}`" @click="$copy('#666868')">#666868</li>

                        <li class="col-3 mb-1 badge rounded-1 me-1 fw-light" :style="`background-color: #49484d; color: ${$autoTxtColor('#49484d')}`" @click="$copy('#49484d')">#49484d</li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{

            // front ui
			pp: false,
			off: false,
            timeout_id: null,

        }
    },
    methods:{

        // front ui
        pp_off(){
            this.off = true;

            if (this.timeout_id) {
                clearTimeout(this.timeout_id);
            }

            this.timeout_id = setTimeout(() => {
                this.pp = false;
                this.off = false;
                if( this.$refs.basic_pp_body ){
                    this.$refs.basic_pp_body.scrollTop = 0;}
                this.timeout_id = null;
            }, 200);
        }
    }

}
</script>