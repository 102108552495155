<template>
    <div class="search_list bg-gray-relative-100">
        <SubHeader title="해외골프장 검색" :link="'/'" class="bg-body" style="padding-bottom:42px"></SubHeader>

        <div>
          <div id="search_box" class="search_box position-sticky zindex-1 pt-3 pb-3 border-bottom border-gray-relative-200 bg-body px-3">
            <select class="form-control thick bg-body border-gray-relative-300 mb-1" :class="{'text-gray-500':location==null, 'text-body':location!=null}" v-model="location" @change="ChangeLocation()">
				<option class="text-body" value="">전체</option>
				<option class="text-body" v-for="(item,index) in location_list" :key="index" :value="item.code">{{item.text}}</option>
			</select>
            <div class="position-relative mb-1">
              <input class="form-control thick text-body bg-body border-gray-relative-300" type="text" placeholder="매장명" v-model="search_name" @input="ChangeLocation()">
              <div class="position-absolute end-0 top-50 translate-middle-y">
                <div class="px-3 small" v-if="search==''" @click="ChangeLocation()"><i class="far fa-search text-gray-500"></i></div>
                <div class="px-3 small" v-else @click="search='',ChangeLocation()"><i class="fas fa-times-circle text-gray-500"></i></div>
              </div>
            </div>
			<div class="badges">
				<!-- <ul class="d-flex w-100 overflow-auto pt-1">
					<li @click="resetFn()"><span class="badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{'bg-gray-relative-200':alltag}">전체</span></li>
					<li v-for="(item,index) in tag" :key="index" @click="selectFn(item)">
						<span class="badge border border-gray-relative-200 text-body rounded-1 me-1 fw-light p-2 px-3" :class="{'bg-gray-relative-200':item.activate}">{{item.type}}</span></li>
				</ul> -->
			</div>
          </div>

          <SearchList :list="list" />
        </div>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import SearchList from '@/components/search/SearchList.vue'
const CryptoJS = require("crypto-js");
export default {
    components:{
        SubHeader, SearchList
    },
    data(){
        return{
			location_list: [],
			location:'',
			search_name : '',
			search :'',
			list: [
			],
			

			alltag:true,
			tag:[
				{
					type:'tag',
					activate:false
				},
				{
					type:'회원제',
					activate:false
				},
				{
					type:'평점 높음',
					activate:false
				},
				{
					type:'스크린',
					activate:false
				},
				{
					type:'추천',
					activate:false
				},
				{
					type:'some',
					activate:false
				},
			],

			
			
		}
    },
	mounted(){
		const login = this.$store.state.login;
		this.GetOverseasComboList()
		if(login ==true){
			this.GetOverseasList();
			
		}else{
			this.GetOverseasListNon();
		}
	},
	methods:{
		ChangeLocation(){
			const login = this.$store.state.login;
			this.GetOverseasComboList()
			if(login ==true){
				this.GetOverseasList();
				
			}else{
				this.GetOverseasListNon();
			}
		},
		GetOverseasComboList(){
			const body = {};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
			this.$http.post('/front/search/GetOverseasComboList',{req}).then(
				(res) =>  { 
					if(res.status == 200){
						this.loading = false;
						if(res.data.code =="200"){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.location_list = body.list;
							
						}else if(res.data.code =="9999"){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									this.$router.push({path:'/signin'});
								}
							)
						}
					}
				}
			);                
		},
		GetOverseasList(){
			const location = this.location;
			const name = this.search_name;
			const body = {name,location};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
			// this.loading = true;
			this.$http.post('/front/search/GetOverseasList',{req}).then(
				(res) =>  { 
					if(res.status == 200){
						this.loading = false;
						if(res.data.code =="200"){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.list = body.list;
							
						}else if(res.data.code =="9999"){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									this.$router.push({path:'/signin'});
								}
							)
						}
					}
				}
			);                

		},
		GetOverseasListNon(){
			const location = this.location;
			const name = this.search_name;
			const body = {name,location};
			const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
			// this.loading = true;
			this.$http.post('/front/search/GetOverseasListNon',{req}).then(
				(res) =>  { 
					if(res.status == 200){
						this.loading = false;
						if(res.data.code =="200"){
							const e_body = res.data.body;
							const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
							const d_res = bytes.toString(CryptoJS.enc.Utf8);
							const body = JSON.parse(d_res)
							this.list = body.list;
							
						}else if(res.data.code =="9999"){
							this.$store.dispatch('SETLOGOUT').then(
								()=>{
									this.$router.push({path:'/signin'});
								}
							)
						}
					}
				}
			);                

		},
		resetFn(){
			this.tag.forEach( item => {
				item.activate = false;
			})
			this.alltag = true;
		},
		selectFn(item){
			if(this.alltag){
				this.alltag = false;
			}
			item.activate = !item.activate;
		}
	}
}
</script>